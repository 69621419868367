import { formatDate } from '@/utils/date'

export const useCoupon = () => {
  const formatExpPreviewText = ({ expType, expDay, endOfPeriodUnit, expAt }) => {
    if (expType === 'expAt') return formatDate(expAt, 'YYYY-MM-DD')
    else if (expType === 'expDay') return `領取後 ${expDay || '-'} 天`
    else if (expType === 'endOfPeriod') {
      if (endOfPeriodUnit === 'month') return '領取當月最後一天'
    }
    return '-'
  }
  return { formatExpPreviewText }
}
